import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';

import { AUTO_LOGOUT_WARNING_MODAL_CONFIG } from 'src/app/core/constants/auto-logout-warning-modal-config.constant';
import { ConfirmModalProperties } from 'src/app/shared/components/modals/data-access/model/confirm-modal-properties.model';
import { InActivityTimerConst } from '../../data-access/config/inactivity-timer';

import { AuthLogoutService } from 'src/app/admin/data-access/services/auth-logout/auth-logout.service';
import { ConfirmModalService } from 'src/app/shared/components/modals/data-access/services/confirm-modal.service';
import { InactivityTimerService } from 'src/app/shell/data-access/services/inactivity-timer/inactivity-timer.service';
import { TimeEventService } from 'src/app/shell/data-access/services/time-event/time-event.service';

@Component({
  selector: 'laps-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  inactivityTimer$!: Observable<boolean>;
  autoLogoutTimer$!: Observable<boolean>;

  totalMinutesIdle =
    InActivityTimerConst.MINUTES_IDLE_ALLOWED +
    InActivityTimerConst.LOGOUT_WARNING_DURATION_IN_MINS;

  @ViewChild('logoutWarningModalContentTemplate')
  logoutWarningModalContentTemplate!: TemplateRef<void> | null;

  constructor(
    private authLogoutService: AuthLogoutService,
    private confirmModalService: ConfirmModalService,
    private inactivityTimerService: InactivityTimerService,
    private autoLogoutTimerService: TimeEventService
  ) {}

  ngOnInit(): void {
    this.inactivityTimer$ = this.inactivityTimerService
      .startMonitoring(InActivityTimerConst.MINUTES_IDLE_ALLOWED)
      .pipe(
        filter((expired: boolean) => !!expired),
        tap(() => {
          this.inactivityTimerService.stopTimer();
          this.initializeAutoLogoutTimer();
          this.displayLogoutWarningModal();
        })
      );
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private displayLogoutWarningModal(): void {
    const modalProps = {
      ...AUTO_LOGOUT_WARNING_MODAL_CONFIG,
      title: AUTO_LOGOUT_WARNING_MODAL_CONFIG.title.replace(
        '[MINUTE]',
        InActivityTimerConst.LOGOUT_WARNING_DURATION_IN_MINS.toString()
      ),
    };

    const options = new ConfirmModalProperties(
      this.logoutWarningModalContentTemplate,
      { ...modalProps }
    );

    this.confirmModalService.open(options);

    this.confirmModalService
      .confirmed()
      .pipe(takeUntil(this._destroying$))
      .subscribe((isConfirmed: boolean) => {
        if (!isConfirmed) {
          this.authLogoutService.logout();
          return;
        }

        this.autoLogoutTimerService.stopTimer();
        this.inactivityTimerService.resetTimer();
      });
  }

  private initializeAutoLogoutTimer(): void {
    this.autoLogoutTimer$ = this.autoLogoutTimerService
      .startMonitoringTimer(
        InActivityTimerConst.LOGOUT_WARNING_DURATION_IN_MINS
      )
      .pipe(
        filter((expired: boolean) => expired),
        tap(() => {
          this.autoLogoutTimerService.stopTimer();
          this.authLogoutService.logout();
        })
      );
  }
}
