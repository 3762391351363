import { createReducer, on } from '@ngrx/store';
import { LoadStatus } from '../../../core/enums/load-status.enum';
import { ProfilePageActions, UserAPIActions } from './actions';
import { User } from './user.entity';

export interface UserState {
  activeUser: User | null;
  _ui: {
    status: LoadStatus;
    isSubmitting: boolean;
  };
}

const initialState: UserState = {
  activeUser: null,
  _ui: {
    status: LoadStatus.NOT_LOADED,
    isSubmitting: false,
  },
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(ProfilePageActions.setActiveUser, (state, action): UserState => {
    const activeUser =
      state._ui.status === LoadStatus.LOADED ? state.activeUser : action.user;

    return {
      ...state,
      activeUser,
      _ui: {
        status: LoadStatus.LOADED,
        isSubmitting: false,
      },
    };
  }),
  on(ProfilePageActions.upsertUser, (state): UserState => {
    return {
      ...state,
      _ui: {
        status: LoadStatus.NOT_LOADED,
        isSubmitting: true,
      },
    };
  }),
  on(UserAPIActions.upsertUserSuccess, (state, action): UserState => {
    const activeUser = { ...action.user };

    if (state.activeUser) {
      activeUser.contractorAgreementUrl =
        state.activeUser.contractorAgreementUrl;
    }

    return {
      ...state,
      activeUser,
      _ui: {
        status: LoadStatus.LOADED,
        isSubmitting: false,
      },
    };
  }),
  on(UserAPIActions.upsertUserFail, (state): UserState => {
    return {
      ...state,
      _ui: {
        status: LoadStatus.NOT_LOADED,
        isSubmitting: false,
      },
    };
  }),
  on(ProfilePageActions.clearActiveUser, (state): UserState => {
    return {
      ...state,
      activeUser: null,
      _ui: {
        status: LoadStatus.NOT_LOADED,
        isSubmitting: false,
      },
    };
  })
);
