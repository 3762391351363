import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MsalInteractionService implements OnDestroy {
  loggedIn$: Observable<boolean>;
  loggedIn: BehaviorSubject<boolean>;

  destroy$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.loggedIn$ = this.loggedIn.asObservable();

    if (this.msalService.instance.getActiveAccount()) {
      this.setIsLoggedIn();
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.setIsLoggedIn();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (!this.msalService.instance.getAllAccounts().length) {
          window.location.pathname = '/';
        }

        this.setIsLoggedIn();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          [EventType.LOGIN_FAILURE, EventType.ACQUIRE_TOKEN_FAILURE].includes(
            msg.eventType
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.setIsLoggedIn();
        this.msalService.logoutRedirect();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkAndSetActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.setIsLoggedIn();
    }
  }

  private setIsLoggedIn(): void {
    this.loggedIn.next(this.msalService.instance.getAllAccounts().length > 0);
  }
}
