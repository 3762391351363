<div mat-dialog-title class="!flex w-full text-lg justify-between">
  <h1 class="flex-auto !mb-0 !mt-1 text-blue-1000 align-bottom">
    Change Status
  </h1>
  <div class="flex-none inline-flex">
    <a class="bg-white-100 !min-w-fit" mat-icon-button mat-dialog-close>
      <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
    </a>
  </div>
</div>
<div mat-dialog-content>
  <p>You are unable to accept this application.</p>
  <p>
    An invoice has been generated, but has not been sent yet. Please login to
    Stripe and send the invoice for payment
  </p>
</div>
<div mat-dialog-actions class="!justify-center !pb-4">
  <button color="primary" mat-stroked-button mat-dialog-close>
    <div class="!px-8">CLOSE</div>
  </button>
</div>
