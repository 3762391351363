import { Component } from '@angular/core';

@Component({
  selector: 'laps-overlay-spinner',
  template: `<div
    class="flex fixed w-full h-full justify-center items-center background-gray-401 backdrop-blur-sm z-[1000]">
    <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
  </div>`,
})
export class OverlaySpinnerComponent {}
