import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { UserApiService } from '../../../core/laps-api';
import { User } from './user.entity';

@Injectable({ providedIn: 'root' })
export class UserFacade {
  readonly user$!: Observable<User>;
  readonly contractorAgreementURL$!: Observable<string>;

  constructor(private userAPIService: UserApiService) {
    this.user$ = this.userAPIService.me().pipe(shareReplay(1));
    this.contractorAgreementURL$ = this.userAPIService
      .getContractorAgreement()
      .pipe(shareReplay(1));
  }
}
