import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorBoxComponent } from './error-box.component';
import { AddClassModule } from '../../directives/add-class/add-class.module';

@NgModule({
  declarations: [ErrorBoxComponent],
  exports: [ErrorBoxComponent],
  imports: [CommonModule, AddClassModule],
})
export class ErrorBoxModule {}
