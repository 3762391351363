import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, finalize, map, mergeMap, tap } from 'rxjs';
import { LoadStatus } from '../../../core/enums/load-status.enum';
import { LookupDataService } from '../../../core/laps-api';
import { OverlaySpinnerService } from '../../../shared/components/overlay-spinner/overlay-spinner.service';
import { AppState } from '../app.reducer';
import { LookupsAPIActions } from './actions';
import { selectLookupDataLoadStatus } from './lookup-data.selectors';

@Injectable()
export class LookupDataEffects {
  private readonly actions$ = inject(Actions);
  private readonly appStore = inject(Store<AppState>);
  private readonly lookupDataService = inject(LookupDataService);
  private readonly spinner = inject(OverlaySpinnerService);

  loadLookupData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LookupsAPIActions.loadLookupData),
      concatLatestFrom(() => this.appStore.select(selectLookupDataLoadStatus)),
      filter(([, loadStatus]) => loadStatus !== LoadStatus.LOADED),
      tap(this.spinner.show),
      mergeMap(() =>
        this.lookupDataService.getLookupData().pipe(
          map((lookupDataState) =>
            LookupsAPIActions.updateLookupData(lookupDataState)
          ),
          finalize(this.spinner.hide)
        )
      )
    )
  );
}
