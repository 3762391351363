import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MsalInteractionService } from 'src/app/core/services/msal-interaction/msal-interaction.service';

import { Subject, takeUntil } from 'rxjs';

import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';

@Component({
  selector: 'laps-root',
  template: `
    <main>
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </main>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  showApplication = false;
  destroy$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalInteractionService: MsalInteractionService
  ) {
    this.msalInteractionService.loggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.showApplication = isLoggedIn;
      });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    setTimeout(() => {
      if (!this.showApplication) {
        this.msalService.logoutRedirect();
      }
    }, 5000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
