import { Component, Input } from '@angular/core';

@Component({
  selector: 'laps-error-box',
  template: `
    <div
      *ngIf="messages.length"
      class="bg-red-100 max-w-full px-5 py-4 rounded-md"
      lapsAddClass
      [addedClass]="addedClass"
      data-test="error-box-container">
      <p *ngFor="let message of messages" class="text-sm text-red-800 !mb-0">
        {{ message }}
      </p>
    </div>
  `,
})
export class ErrorBoxComponent {
  @Input() addedClass = '';
  @Input() messages: string[] = [];
}
