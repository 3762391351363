<div
  *ngIf="config?.showUploadedFiles && files.length > 0"
  class="flex flex-col">
  <div
    *ngFor="let file of files"
    class="flex flex-col rounded-md bg-white-100 pl-2 mb-2">
    <div class="flex items-center">
      <ng-container
        *ngIf="file.isClean === false || file.hasTimedOut; else notInfected">
        <div class="flex text-xs text-red-1000 basis-8/10">
          <mat-icon class="text-base text-red-1000 mr-5 overflow-inherit"
            >error</mat-icon
          >
          Something went wrong with your file {{ file?.name }}. Please make sure
          you are uploading a file with PDF version 1.7 or greater, or convert
          the file to an image (JPG, PNG, JPEG).
        </div>
      </ng-container>
      <ng-template #notInfected>
        <a
          [href]="file.url"
          target="_blank"
          class="text-blue-1000 basis-2/3 file-name">
          {{ file.name }}
        </a>
      </ng-template>

      <div class="flex basis-1/3 justify-end">
        <ng-container
          *ngIf="
            file.isDeleting || (file.isClean === null && !file.hasTimedOut);
            else notDeleting
          ">
          <span class="flex items-center justify-center w-[40px] h-[40px]">
            <mat-spinner diameter="24" strokeWidth="2"></mat-spinner>
          </span>
        </ng-container>
        <ng-template #notDeleting>
          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="removeFile.emit(file)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="
    config &&
    ((config.multiple && files.length < 10) ||
      (!config.multiple && !files.length))
  "
  class="file-upload-wrapper bg-white-100 min-w-[50%]">
  <button
    type="button"
    mat-stroked-button
    class="w-full"
    (click)="fileUploadInput.click()"
    [disabled]="isUploading">
    <div class="flex items-center justify-start gap-2">
      <ng-container *ngIf="isUploading; else uploading">
        <mat-spinner diameter="24" strokeWidth="2" class="mr-1"></mat-spinner>
        <span class="text-gray-500">{{ config.loaderText }}</span>
      </ng-container>
    </div>
    <ng-template #uploading>
      <mat-icon class="!text-blue-1000">file_upload</mat-icon>
      <span class="text-blue-1000">{{ config.label }}</span>
    </ng-template>
  </button>
  <input
    aria-label="select-file"
    id="select-file"
    #fileUploadInput
    type="file"
    hidden
    [accept]="config.requiredFileType"
    (click)="reset()" />
</div>
