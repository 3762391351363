import { IConfirmModalCommonProps } from '../../shared/components/modals/data-access/interfaces/confirm-modal-common-props';

export const AUTO_LOGOUT_WARNING_MODAL_CONFIG: IConfirmModalCommonProps = {
  title: 'You will be logged out in [MINUTE] minutes',
  hasNoOption: true,
  confirmText: 'Stay Logged In',
  cancelText: 'Logout',
  disableClose: true,
  cancelOnClose: false,
  height: '250px',
  width: '450px',
};
