import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[lapsAddClass]',
})
export class AddClassDirective implements OnInit {
  @Input() addedClass = '';

  @HostBinding('class')
  elementClass = '';

  ngOnInit(): void {
    this.elementClass = this.addedClass;
  }
}
