import { DukeUser } from '../../../../../admin/data-access/models/duke-user.model';
import { createAction, props } from '@ngrx/store';

export const updateDukeUserSuccess = createAction(
  '[Duke Users API] Update Duke Users Data Success',
  props<{ dukeUsers: DukeUser[] }>()
);

export const updateDukeUserFail = createAction(
  '[Duke Users API] Update Duke Users Data Fail'
);
