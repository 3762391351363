import { PaymentDetailTypeEnum } from 'src/app/core/enums/payment-detail-type.enum';
import { FeeAmountDefaults } from '../models/fee-amount-defaults.model';

export class AdditionalFeeConstant {
  static readonly maximumAmount = 999999.99;
  static readonly maximumFeesLength = 10;
  static readonly additionalPaymentTransactionType = 3;

  static additionalFeeDefaults(): FeeAmountDefaults {
    return {
      minimumAmount: 1,
      maximumAmount: 10000,
      maxNumberOfDigits: 9,
    };
  }

  static generatedFeeDefaults(): FeeAmountDefaults {
    return {
      minimumAmount: 0,
      maximumAmount: 99999.99,
      maxNumberOfDigits: 9,
    };
  }

  static isValidAdditionalPaymentDetailType(id: number | null): boolean {
    if (!id) {
      return true; // * This means payment.paymentDetails[0] has a value of null so it is considered additional payment.
    }

    const { ADDITIONAL_HEP_FEE_FOR_CATAWBA_WATEREE_NC } = PaymentDetailTypeEnum;
    const { AFTER_THE_FACT_APPLICATION_FEE } = PaymentDetailTypeEnum;

    const isMinimumId = id >= ADDITIONAL_HEP_FEE_FOR_CATAWBA_WATEREE_NC;
    const isMaximumId = id <= AFTER_THE_FACT_APPLICATION_FEE;
    const isMiscellaneousId = id === PaymentDetailTypeEnum.MISCELLANEOUS_FEE;

    return (isMinimumId && isMaximumId) || isMiscellaneousId;
  }
}
