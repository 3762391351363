export const INACTIVITY_TIMER_EVENTS = [
  {
    scope: document,
    event: 'mousemove',
  },
  {
    scope: document,
    event: 'click',
  },
  {
    scope: document,
    event: 'mousedown',
  },
  {
    scope: document,
    event: 'keyup',
  },
  {
    scope: document,
    event: 'DOMMouseScroll',
  },
  {
    scope: document,
    event: 'mousewheel',
  },
  {
    scope: document,
    event: 'touchmove',
  },
  {
    scope: document,
    event: 'MSPointerMove',
  },
  {
    scope: window,
    event: 'mousemove',
  },
  {
    scope: window,
    event: 'resize',
  },
];
