import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/core/app-config/app.config';
import { AppStatus } from '../../admin/data-access/models/app-status.model';
import { APP_CONFIG } from '../app-config/app-config.token';
import { Application } from '../../store/global/applicant/applications/entities/application.entity';

@Injectable({
  providedIn: 'root',
})
export class StatusApiService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  update(appStatus: AppStatus, id: number): Observable<Application> {
    return this.http.patch<Application>(
      `${this.appConfig.apiBaseURL}/applications/${id}/status`,
      appStatus
    );
  }
}
