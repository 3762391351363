import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { TimeUtil } from '../../../../core/utils/time';

@Injectable({ providedIn: 'root' })
export class TimeEventService {
  public expired = new BehaviorSubject(false);

  private eventTimer = new Subscription();
  private totalTimeInMilliseconds!: number;

  /**
   * Initializes a timer and returns an observable once the timer has elapsed
   * @param {number} timeOutInMinutes - The number of minutes the timer needs to run
   * @returns Observable<boolean>
   */
  public startMonitoringTimer(timeOutInMinutes: number): Observable<boolean> {
    this.totalTimeInMilliseconds = TimeUtil.convertSecondsToMilliSeconds(
      TimeUtil.convertMinutesToSeconds(timeOutInMinutes)
    );

    this.startTimer();

    return this.expired;
  }

  /**
   * Starts the timer
   */
  public startTimer(): void {
    this.eventTimer = timer(this.totalTimeInMilliseconds).subscribe(() =>
      this.expired.next(true)
    );
  }

  /**
   * Stops the timer
   */
  public stopTimer(): void {
    this.eventTimer.unsubscribe();
  }
}
