import { createAction, props } from '@ngrx/store';
import { StatusRule } from '../../../../../admin/data-access/models/status-rules';

export const loadStatusRules = createAction('[StatusRule] Load StatusRules');

export const loadStatusRulesSuccess = createAction(
  '[StatusRule] Load StatusRules Success',
  props<{ statusRules: StatusRule[] }>()
);

export const loadStatusRulesFailure = createAction(
  '[StatusRule] Load StatusRules Failure'
);
