import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalProperties } from '../data-access/model/confirm-modal-properties.model';

@Component({
  selector: 'laps-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalProperties,
    public dialogRef: MatDialogRef<ConfirmModalComponent>
  ) {}

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public closeModal(): void {
    if (this.data.cancelOnClose) {
      this.cancel();
      return;
    }

    this.confirm();
  }
}
