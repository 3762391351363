<div class="flex justify-center lg:gap-16 sm:gap-8">
  <button
    (click)="onBackButtonClick()"
    class="!py-6"
    color="primary"
    data-test="back-button"
    mat-stroked-button
    type="button">
    BACK
  </button>
  <button
    (click)="onNextButtonClick()"
    class="!py-6"
    color="primary"
    data-test="next-button"
    mat-flat-button>
    NEXT
  </button>
</div>
