const SECONDS_IN_MINUTES = 60;
const MILLISECONDS_TO_SECONDS = 1000;

export class TimeUtil {
  static convertMinutesToSeconds(minutes: number): number {
    return minutes * SECONDS_IN_MINUTES;
  }

  static convertSecondsToMilliSeconds(seconds: number): number {
    return seconds * MILLISECONDS_TO_SECONDS;
  }

  static getTime(date: string | Date): number {
    return new Date(date).getTime();
  }
}
