import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusRule } from 'src/app/admin/data-access/models/status-rules';
import { AppConfig } from 'src/app/core/app-config/app.config';
import { APP_CONFIG } from '../app-config/app-config.token';

@Injectable({
  providedIn: 'root',
})
export class RulesEngineApiService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  getAllStatusRules(): Observable<StatusRule[]> {
    return this.http.get<StatusRule[]>(
      `${this.appConfig.apiBaseURL}/rules-engine/app-statuses`
    );
  }
}
