import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModalComponent } from './loading-modal.component';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
  declarations: [LoadingModalComponent],
  exports: [LoadingModalComponent],
  imports: [CommonModule, MaterialModule],
})
export class LoadingModalModule {}
