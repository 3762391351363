import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { APP_CONFIG } from '../app-config/app-config.token';
import { AppConfig } from '../app-config/app.config';

import { User } from 'src/app/store/global/user/user.entity';
import { HttpParamCodec } from '../utils/http-param-codec';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private http: HttpClient
  ) {}

  findAll(): Observable<User[]> {
    return this.http.get<User[]>(`${this.appConfig.apiBaseURL}/users`);
  }

  me(email?: string): Observable<User> {
    if (email) {
      const params = new HttpParams({ encoder: new HttpParamCodec() }).set(
        'email',
        email
      );

      return this.http.get<User>(`${this.appConfig.apiBaseURL}/users/me`, {
        params,
      });
    }

    return this.http.get<User>(`${this.appConfig.apiBaseURL}/users/me`);
  }

  upsert(user: User): Observable<User> {
    return this.http.post<User>(`${this.appConfig.apiBaseURL}/users`, user);
  }

  getContractorAgreement(): Observable<string> {
    return this.http
      .get<{ contractorAgreementUrl: string }>(
        `${this.appConfig.apiBaseURL}/users/contractor-agreement`
      )
      .pipe(map(({ contractorAgreementUrl }) => contractorAgreementUrl));
  }
}
