import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfirmModalComponent } from './confirm-modal.component';
import { ConfirmModalService } from '../data-access/services/confirm-modal.service';
import { MaterialModule } from 'src/app/shared/modules/material.module';

@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ConfirmModalComponent],
  providers: [ConfirmModalService],
})
export class ConfirmModalModule {}
