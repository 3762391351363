import { AppConfig } from 'src/app/core/app-config/app.config';

export const environment: AppConfig = {
  production: false,
  name: 'dev',
  apiBaseURL: 'https://laps-api-dev.duke-energy.app',
  staticAssetsURL:
    'https://laps-web-static-assets-dev.s3.us-east-1.amazonaws.com',
  dirtyBucketURL:
    'https://laps-web-application-documents-dirty-dev.s3.us-east-1.amazonaws.com',

  azureB2C: {
    authority:
      'https://dukeenergytestb2c.com/dukeenergytestb2c.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    tenant: 'dukeenergytestb2c',
    clientId: '789f97e5-b5c1-42f3-b065-2c3942a0fe99',
    tenantId: '56f1d127-82a7-4fb4-80fd-84ce35a5abdf',
    policyName: 'B2C_1_DukeId_SignUp_SignIn',
  },

  smarty: {
    suggestionsEndpointURL:
      'https://us-autocomplete-pro.api.smartystreets.com/lookup',
    coordinatesEndpointURL:
      'https://us-street.api.smartystreets.com/street-address',
  },

  castproDocumentUrl:
    'https://cast-pro-qa.duke-energy.app/document?tenantId=enterprise-pro',
};
