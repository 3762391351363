import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, filter, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../../store/global/app.reducer';
import { LookupsAPIActions } from '../../../store/global/lookup-data/actions';
import { selectLookupDataLoadStatus } from '../../../store/global/lookup-data/lookup-data.selectors';
import { LoadStatus } from '../../enums/load-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LookupDataGuard {
  private readonly appStore = inject(Store<AppState>);

  canActivate(): Observable<boolean> {
    return this.getLookupData().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private getLookupData(): Observable<LoadStatus> {
    return this.appStore.select(selectLookupDataLoadStatus).pipe(
      tap((status) => this.prefetch(status)),
      filter((status) => status === LoadStatus.LOADED),
      take(1)
    );
  }

  private prefetch(status: LoadStatus): void {
    if (status === LoadStatus.NOT_LOADED) {
      this.appStore.dispatch(LookupsAPIActions.loadLookupData());
    }
  }
}
