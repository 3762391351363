import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthLogoutService {
  constructor(private authService: MsalService) {}

  logout(): void {
    this.authService.logoutRedirect();
  }
}
