import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DukeUser } from '../../admin/data-access/models/duke-user.model';
import { DukeUsersFacade } from '../facades/duke-users.facade';

@Injectable({
  providedIn: 'root',
})
export class DukeUsersResolver implements Resolve<DukeUser[] | null> {
  constructor(private dukeUsersFacade: DukeUsersFacade) {}

  resolve(): Observable<DukeUser[]> {
    return this.dukeUsersFacade.dukeUsers$;
  }
}
