import { createReducer, on } from '@ngrx/store';
import { SiteStatus } from '../../../admin/data-access/models/site-status.model';
import { LoadStatus } from '../../../core/enums/load-status.enum';
import { County } from '../../../core/models/county.model';
import { PaymentDetailType } from '../../../core/models/payment-detail-type.interface';
import { LookupsAPIActions } from './actions';
import {
  DocumentType,
  ILake,
  ILookupField,
  State,
} from './entities/lookup-fields.entity';

export interface LookupDataState {
  loadStatus: LoadStatus;
  lakes: ILake[];
  states: State[];
  permitTypeActivities: ILookupField[];
  paymentDetailTypes: PaymentDetailType[];
  siteStatus: SiteStatus[];
  counties: County[];
  documentTypes: DocumentType[];
}

const initialState: LookupDataState = {
  loadStatus: LoadStatus.NOT_LOADED,
  lakes: [],
  states: [],
  permitTypeActivities: [],
  paymentDetailTypes: [],
  siteStatus: [],
  counties: [],
  documentTypes: [],
};

export const lookupDataReducer = createReducer<LookupDataState>(
  initialState,
  on(LookupsAPIActions.loadLookupData, (state): LookupDataState => {
    return {
      ...state,
      loadStatus: LoadStatus.LOADING,
    };
  }),
  on(
    LookupsAPIActions.updateLookupData,
    (
      _state,
      {
        lakes,
        states,
        permitTypeActivities,
        paymentDetailTypes,
        siteStatus,
        counties,
        documentTypes,
      }
    ): LookupDataState => {
      return {
        lakes,
        states,
        permitTypeActivities,
        paymentDetailTypes,
        siteStatus,
        counties,
        documentTypes,
        loadStatus: LoadStatus.LOADED,
      };
    }
  )
);
