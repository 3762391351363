export enum DocumentType {
  PROPERTY_SURVEY = 1,
  PLAN_DRAWING = 2,
  PROFILE_DRAWING = 3,
  DWQ = 4,
  PROOF_OF_VALID_SHORELINE_LEASE = 5,
  SHORELINE_LEASE_APPLICATION = 6,
  GENERAL_WARRANTY_DEED = 7,
  CERTIFICATE_OF_INSURANCE = 8,
  DNR_DHEC_USACE = 9,
  COUNTY_BUILDING_PERMIT = 10,
  CITY_LOCAL_BUILDING_PERMITS = 11,
  MISCELLANEOUS = 12,
  APPLICATION_FORM = 13,
  UA_TC = 14,
  USER_CONTRACTOR_AGREEMENT = 15,
  USER_CONTRACTOR_AGREEMENT_TC = 16,
  APP_FORM_UA_TC = 17,
  APP_FORM_ALL_AGREEMENTS = 18,
  APP_FORM_AGREEMENTS_TC = 19,
  TC = 20,
  MECKLENBURG_COUNTY_STORM_WATER_PERMIT = 21,
}
