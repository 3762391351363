import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../modules/material.module';

import { OverlaySpinnerComponent } from './overlay-spinner.component';
import { OverlaySpinnerService } from './overlay-spinner.service';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [OverlaySpinnerComponent],
  declarations: [OverlaySpinnerComponent],
  providers: [OverlaySpinnerService],
})
export class OverlaySpinnerModule {}
