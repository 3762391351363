<div>
  <router-outlet></router-outlet>

  <ng-container *ngIf="(inactivityTimer$ | async) !== undefined">
    <ng-template
      *ngIf="(autoLogoutTimer$ | async) !== undefined"
      #logoutWarningModalContentTemplate>
      <div>
        <p>
          For your security, sessions automatically end after
          {{ totalMinutesIdle }} minutes <br />
          of inactivity unless you choose to stay logged in.
        </p>
      </div>
    </ng-template>
  </ng-container>
</div>
