import { Injectable } from '@angular/core';

import { map, Observable, take } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmModalProperties } from '../model/confirm-modal-properties.model';

import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';

@Injectable({ providedIn: 'root' })
export class ConfirmModalService {
  constructor(public dialog: MatDialog) {}

  dialogRef!: MatDialogRef<ConfirmModalComponent>;

  public open(options: ConfirmModalProperties): void {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: options,
      ...options,
    });
  }

  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((isClosed) => isClosed)
    );
  }
}
