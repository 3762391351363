import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../../../shared/components/toaster/toaster.component';
import { IToasterConfig } from '../../models/toaster-config.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar) {}

  showToaster(config: IToasterConfig) {
    this.snackBar.openFromComponent(ToasterComponent, config);
  }
}
