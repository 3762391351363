import { Component } from '@angular/core';

@Component({
  selector: 'laps-loading-modal',
  template: `
    <div class="w-full grid place-items-center">
      <mat-spinner diameter="50" strokeWidth="3"></mat-spinner>
    </div>
  `,
})
export class LoadingModalComponent {}
