import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'laps-stepper-nav-buttons',
  templateUrl: './stepper-nav-buttons.component.html',
  styleUrls: ['./stepper-nav-buttons.component.scss'],
})
export class StepperNavButtonsComponent {
  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  onBackButtonClick(): void {
    this.back.emit();
  }

  onNextButtonClick(): void {
    this.next.emit();
  }
}
