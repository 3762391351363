import { Route } from '@angular/router';

import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

import { RoleGuard } from 'src/app/core/guards/role/role.guard';

import { Role } from 'src/app/core/enums/role.enum';

import { AuthGuard } from 'src/app/core/guards/auth/auth.guard';
import { DukeUsersResolver } from 'src/app/core/resolvers/duke-users.resolver';
import { ForbiddenComponent } from 'src/app/shared/components/auth/forbidden/forbidden.component';
import { UnauthorizedComponent } from 'src/app/shared/components/auth/unauthorized/unauthorized.component';
import { AppRoute } from '../../../core/constants/app-route.constant';
import { LookupDataGuard } from '../../../core/guards/lookup-data/lookup-data.guard';
import { LayoutComponent } from '../../ui/layout/layout.component';

export const webShellRoutes: Route[] = [
  {
    path: 'home',
    component: LayoutComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: AppRoute.admin.dashboard,
        loadChildren: async () =>
          (await import('../../../admin/feature/dashboard/dashboard.module'))
            .DashboardModule,
        canActivate: [RoleGuard, LookupDataGuard],
        resolve: {
          dukeUsers: DukeUsersResolver,
        },
        data: {
          roles: [Role.ADMIN],
        },
      },
      {
        path: AppRoute.applicant.applications,
        loadChildren: async () =>
          (
            await import(
              '../../../applicant/feature/applications-shell/applications-shell.module'
            )
          ).ApplicationsShellModule,
        canActivate: [RoleGuard, AuthGuard, LookupDataGuard],
        data: {
          roles: [Role.EXTERNAL],
        },
      },
      {
        path: AppRoute.profile,
        loadChildren: async () =>
          (await import('../../../profile/profile.module')).ProfileModule,
        canActivate: [RoleGuard, AuthGuard, LookupDataGuard],
        data: {
          roles: [Role.EXTERNAL],
        },
      },
      {
        path: '',
        redirectTo: AppRoute.admin.dashboard,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: AppRoute.forbidden,
    component: ForbiddenComponent,
  },
  {
    path: AppRoute.unauthorized,
    component: UnauthorizedComponent,
  },

  /**
   * Needed for login on page load for PathLocationStrategy.
   * See FAQ for details:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/FAQ.md
   */
  {
    path: AppRoute.msalAuth,
    component: MsalRedirectComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
