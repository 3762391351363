import { ToasterStatus } from '../enums/toaster-status.enum';
import { IToasterConfig } from '../models/toaster-config.model';

const CREATE_USER_SUCCESS: IToasterConfig = {
  duration: 4000,
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass: ['toast-success'],
  data: {
    status: ToasterStatus.SUCCESS,
    message: 'Your profile has been successfully created',
    showIcon: true,
  },
};

const UPDATE_USER_SUCCES: IToasterConfig = {
  ...CREATE_USER_SUCCESS,
  duration: 4000,
  data: {
    status: ToasterStatus.SUCCESS,
    message: 'Profile has been successfully saved.',
    showIcon: true,
  },
};

const ERROR: IToasterConfig = {
  duration: 0,
  horizontalPosition: 'center',
  verticalPosition: 'top',
  panelClass: ['toast-error'],
  data: {
    status: ToasterStatus.FAIL,
    message: 'There seems to have been a technical issue. Please try again.',
    showIcon: true,
    showAction: true,
  },
};

export const USER_TOASTER_CONFIG = {
  createUserSuccess: CREATE_USER_SUCCESS,
  updateUserSuccess: UPDATE_USER_SUCCES,
  error: ERROR,
};
