import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  FileError,
  UploadedFile,
} from '../../../core/models/uploaded-file.model';
import { FileUtils } from '../../../core/utils/file-utils';

export interface FileUploadConfig {
  label?: string;
  requiredFileType?: string;
  multiple?: boolean;
  showUploadedFiles?: boolean;
  loaderText?: string;
}

@Component({
  selector: 'laps-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadComponent,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() config: FileUploadConfig | undefined = {
    label: 'UPLOAD A FILE',
    requiredFileType: '',
    multiple: true,
    showUploadedFiles: true,
    loaderText: 'UPLOADING...',
  };
  @Input() isUploading: boolean | undefined;
  @Output() removeFile = new EventEmitter<UploadedFile>();
  @Output() fileError = new EventEmitter<FileError | undefined>();
  @ViewChild('fileUploadInput') fileUploadInput!: ElementRef;

  files: UploadedFile[] = [];

  onChange = (files: UploadedFile[]) => {};
  onTouched = () => {};
  touched = false;

  @HostListener('change', ['$event.target.files']) emitFiles(
    fileList: FileList
  ) {
    const newFiles = Array.from(fileList || []);
    if (newFiles.some((file) => !FileUtils.isSizeValid(file, 50000))) {
      return this.fileError.emit('size');
    }

    if (
      newFiles.some(
        (file) =>
          !FileUtils.isTypeValid(
            file,
            this.config?.requiredFileType?.toLowerCase()?.split(',') || []
          )
      )
    ) {
      return this.fileError.emit('type');
    }

    if (newFiles.some((file) => !FileUtils.isFilenameValid(file))) {
      return this.fileError.emit('filename-chars');
    }

    if (newFiles.some((file) => !FileUtils.isFilenameLengthValid(file, 120))) {
      return this.fileError.emit('filename-length');
    }

    this.fileError.emit(undefined);

    const files = [
      ...this.files,
      ...newFiles.map((file: File) => {
        return {
          name: file.name,
          file,
        } as UploadedFile;
      }),
    ];

    this.onTouched();
    this.onChange(files);
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  reset(): void {
    this.fileUploadInput.nativeElement.value = null;
  }

  writeValue(files: UploadedFile[]): void {
    this.host.nativeElement.value = '';
    this.files = files;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
