export const KILOBYTE = 1024;
export const ALLOWED_FILE_TYPES = '.pdf,.jpeg,.jpg,.png';
export type FileUploadStatus = 'pending' | 'uploading' | 'uploaded' | 'timeout';

export class FileUtils {
  static isSizeValid(file: File, maxSize: number): boolean {
    return Math.round(file.size / KILOBYTE) <= maxSize;
  }

  static isTypeValid(file: File, validTypes: string[]): boolean {
    return validTypes.includes(`.${file.name.split('.').pop()?.toLowerCase()}`);
  }

  static isFilenameValid(file: File): boolean {
    const regex =
      /^[a-zA-Z0-9()\s-]*\.(pdf|jpeg|jpg|png|txt|PDF|JPEG|JPG|PNG|TXT)$/g;

    return regex.test(file.name);
  }

  static isFilenameLengthValid(file: File, maxCharacters: number): boolean {
    return file.name.length <= maxCharacters;
  }

  static base64ToBlob(base64Data: string): Blob {
    const sliceSize = KILOBYTE;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  static openBase64InNewTab(base64Data: string): void {
    const blob = FileUtils.base64ToBlob(base64Data);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }

  static base64ToArrayBuffer(base64: string): Uint8Array {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }

  static getURLFileExtension(url: string): string | undefined {
    return url.split(/[#?]/)[0].split('.').pop()?.trim();
  }
}
