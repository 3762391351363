import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { OverlaySpinnerComponent } from './overlay-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class OverlaySpinnerService {
  private overlayRef: OverlayRef | undefined;
  private isShowing = false;
  private runningProcess = 0;

  constructor(private overlay: Overlay) {}

  show = (): void => {
    this.runningProcess += 1;
    if (this.isShowing) {
      return;
    }

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    this.isShowing = true;
    this.overlayRef.attach(new ComponentPortal(OverlaySpinnerComponent));
  };

  hide = (): void => {
    this.runningProcess -= this.runningProcess > 0 ? 1 : 0;
    if (this.overlayRef && this.isShowing && this.runningProcess === 0) {
      this.isShowing = false;
      this.overlayRef.detach();
    }
  };
}
