<div class="flex items-center gap-2 pr-1">
  <ng-container *ngIf="customData.showIcon">
    <mat-icon
      *ngIf="customData.status === ToasterStatus.SUCCESS; else failIcon"
      data-test="success-icon"
      >check_circle</mat-icon
    >
    <ng-template #failIcon>
      <mat-icon data-test="fail-icon">error</mat-icon>
    </ng-template>
  </ng-container>
  <span class="font-semibold" data-test="toaster-message">{{
    customData.message
  }}</span>
  <button
    *ngIf="customData.showAction"
    class="flex ml-16"
    (click)="snackBarRef.dismiss()"
    data-test="toaster-action-btn">
    <mat-icon>close</mat-icon>
  </button>
</div>
