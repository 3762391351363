import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { BrowserUtils } from '@azure/msal-browser';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appReducers } from 'src/app/store/global/app.reducer';
import { environment } from 'src/environments/environment';
import { UserEffects } from '../../../store/global/user/user.effects';

import { LayoutModule } from '../../ui/layout/layout.module';

import { OverlaySpinnerModule } from '../../../shared/components';
import { LookupDataEffects } from '../../../store/global/lookup-data/lookup-data.effects';
import { webShellRoutes } from './web-shell.routes';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlaySpinnerModule,
    RouterModule.forRoot(webShellRoutes, {
      useHash: false,
      // Don't perform initial navigation in iframes
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledBlocking'
          : 'disabled',
      scrollPositionRestoration: 'top',
    }),
    EffectsModule.forRoot([UserEffects, LookupDataEffects]),
    StoreModule.forRoot(appReducers),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ logOnly: !environment.production }),
  ],
  exports: [RouterModule],
})
export class WebShellModule {}
