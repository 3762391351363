import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, finalize, map, of, tap } from 'rxjs';
import { USER_TOASTER_CONFIG } from '../../../core/constants/user-toaster.config';
import { UserApiService } from '../../../core/laps-api';
import { IToasterConfig } from '../../../core/models/toaster-config.model';
import { ToasterService } from '../../../core/services/toaster/toaster.service';
import { OverlaySpinnerService } from '../../../shared/components/overlay-spinner/overlay-spinner.service';
import { ProfilePageActions, UserAPIActions } from './actions';
import { User } from './user.entity';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private spinner: OverlaySpinnerService,
    private toasterService: ToasterService,
    private userService: UserApiService
  ) {}

  upsertUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfilePageActions.upsertUser),
      tap(this.spinner.show),
      exhaustMap((action) =>
        this.userService.upsert(action.user).pipe(
          tap(action.callback),
          map((user: User) =>
            UserAPIActions.upsertUserSuccess({ user, id: action.user.id })
          ),
          catchError(() => of(UserAPIActions.upsertUserFail())),
          finalize(this.spinner.hide)
        )
      )
    )
  );

  upsertUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAPIActions.upsertUserSuccess),
        tap(({ id }) => {
          if (id) {
            this.onSuccess(USER_TOASTER_CONFIG.updateUserSuccess);
            return;
          }

          this.onSuccess(USER_TOASTER_CONFIG.createUserSuccess);
        })
      ),
    { dispatch: false }
  );

  upsertUserFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAPIActions.upsertUserFail),
        tap(() => this.onFail(USER_TOASTER_CONFIG.error))
      ),
    { dispatch: false }
  );

  private onFail(config: IToasterConfig): void {
    this.toasterService.showToaster(config);
  }

  private onSuccess(config: IToasterConfig): void {
    this.toasterService.showToaster(config);
  }
}
