import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { environment } from 'src/environments/environment';

import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';

import {
  baseInterceptorProvider,
  httpErrorInterceptorProvider,
} from './core/interceptors';

import { AppComponent } from './app.component';
import { WebShellModule } from './shell/feature/web-shell/web-shell.module';
import { getAppConfigProvider } from './core/app-config/app-config.token';
import { MSALModule } from './core/modules/msal.factory';

@NgModule({
  declarations: [AppComponent],
  imports: [
    WebShellModule,
    BrowserModule,
    MsalModule,
    MSALModule,
    HttpClientModule,
  ],
  providers: [
    getAppConfigProvider(environment),
    baseInterceptorProvider,
    httpErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
