import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoiceNotSentTemplateComponent } from './invoice-not-sent-template.component';
import { MaterialModule } from 'src/app/shared/modules/material.module';

@NgModule({
  declarations: [InvoiceNotSentTemplateComponent],
  exports: [InvoiceNotSentTemplateComponent],
  imports: [CommonModule, MaterialModule],
})
export class InvoiceNotSentTemplateModule {}
