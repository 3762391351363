import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdditionalFeeConstant } from '../../../admin/data-access/constants/additional-fee.constant';
import { AppState } from '../app.reducer';
import { LookupDataState } from './lookup-data.reducer';
import { DocumentType } from 'src/app/core/enums/document-type.enum';

export const LOOKUP_DATA_FEATURE_KEY = 'lookup-data';

export const lookupDataFeatureState = createFeatureSelector<LookupDataState>(
  LOOKUP_DATA_FEATURE_KEY
);

export const selectLookupDataState = (state: AppState) => state.lookupData;

export const selectLookupDataLoadStatus = createSelector(
  selectLookupDataState,
  ({ loadStatus }) => loadStatus
);

export const selectPaymentDetailTypes = createSelector(
  selectLookupDataState,
  ({ paymentDetailTypes }) => paymentDetailTypes
);

export const selectAdditionalPaymentDetailTypes = createSelector(
  selectPaymentDetailTypes,
  (paymentDetailTypes) =>
    paymentDetailTypes?.filter((paymentDetailType) => {
      const id = paymentDetailType.id;
      return AdditionalFeeConstant.isValidAdditionalPaymentDetailType(id);
    })
);

export const selectLakesLookupData = createSelector(
  selectLookupDataState,
  (state: LookupDataState) => state.lakes
);

export const selectStatesLookupData = createSelector(
  selectLookupDataState,
  (state: LookupDataState) => state.states
);

export const selectPermitTypeActivitiesLookupData = createSelector(
  selectLookupDataState,
  (state: LookupDataState) => state.permitTypeActivities
);

export const selectUploadableDocumentTypesLookupData = createSelector(
  selectLookupDataState,
  (state: LookupDataState) =>
    state.documentTypes
      .filter((dt) => !dt.isGenerated)
      .sort((a, b) => {
        // Misc should always display last
        if (a.id === DocumentType.MISCELLANEOUS) {
          return 1;
        }
        if (b.id === DocumentType.MISCELLANEOUS) {
          return -1;
        }
        return 0;
      })
);
