export enum PaymentDetailTypeEnum {
  EXCEPTION = 1,
  APPLICATION_FEE = 2,
  HEP_FEE_NC = 3,
  HEP_FEE_SC = 4,
  HEP_FEE_KT = 5,
  ADDITIONAL_HEP_FEE_FOR_CATAWBA_WATEREE_NC = 6,
  ADDITIONAL_HEP_FEE_FOR_CATAWBA_WATEREE_SC = 7,
  ADDITIONAL_HEP_FEE_FOR_KEOWEE_TOXAWAY = 8,
  AFTER_THE_FACT_APPLICATION_FEE = 9,
  PROCESSING_FEE = 10,
  MISCELLANEOUS_FEE = 11,
  LEASE_APPLICATION_FEE = 12,
  EXCAVATION_APPLICATION_FEE = 13,
}
